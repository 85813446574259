import React, { useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useLocation, NavLink } from "react-router-dom";
import { showHeaderUi } from "../../helpers/general";
import {
  // APP_CONTACT_EMAIL,
  APP_LOGO,
  APP_LOGO_TEXT,
  APP_NAME,
  LOCAL_STORAGE,
} from "../../helpers/constants";
import { trackEvent } from "../../helpers/mixpanel";
import { BackLocationsContext } from "../../context/BackLocationsProvider";
import { device, useBreakpoints } from "../../lib/breakpoints";
import Button from "../Button";
import { AuthContext } from "../../context/AuthProvider";
import { Link } from "react-router-dom";
import Hidden from "../Hidden";
import {
  DIALOG_NAME,
  useDialogSearchParam,
  useOpenParamDialog,
} from "../../helpers/useDialogSearchParam";
import FeedDrawer from "../feed/FeedDrawer";
import { firebaseAuth } from "../../helpers/authentication";
import { Dropdown } from "antd";
import { EditModal, SettingsModal } from "../profile";
import { navigate } from "@storybook/addon-links";

const profileIcon = "/icons/profile.svg";

export default function NavBar() {
  const location = useLocation();
  const openSignup = useOpenParamDialog(DIALOG_NAME.signup);
  const { user, userInfo, requireLogin, requireSignUp } =
    useContext(AuthContext);
  const { clearBackLocations } = useContext(BackLocationsContext);
  const { isMobile, isTabletOrAbove } = useBreakpoints();

  // const [hasNotifications, setHasNotifications] = useState(false);
  const [feedDrawerVisible, setFeedDrawerVisible] = useState(false);

  const [isEditParamSet, removeEditParam] = useDialogSearchParam(
    DIALOG_NAME.edit
  );
  const [isSettingsParamSet, removeSettingsParam] = useDialogSearchParam(
    DIALOG_NAME.settings
  );

  const onLogout = async () => {
    try {
      await firebaseAuth.signOut();
    } catch (error) {
      console.error("Sign Out Error", error);
    }
  };

  // Close the feed drawer when the url changes
  useEffect(() => {
    setFeedDrawerVisible(false);
    async function signOut() {
      await firebaseAuth.signOut();
    }
    // signOut();
  }, [location]);

  const items = [
    {
      label: (
        <StyledLink to={isMobile ? "/profile/edit" : `?${DIALOG_NAME.edit}=`}>
          Edit Profile
        </StyledLink>
      ),
      key: "0",
    },
    {
      label: (
        <StyledLink
          to={isMobile ? "/profile/edit" : `?${DIALOG_NAME.settings}=`}
        >
          Account Settings
        </StyledLink>
      ),
      key: "1",
    },
    {
      label: <StyledLink onClick={onLogout}>Log Out</StyledLink>,
      key: "2",
    },
  ];

  /*
  useEffect(() => {
    function checkUserAlerts() {
      const hasAlerts = localStorage.getItem(LOCAL_STORAGE.hasAlerts);
      const parsed = JSON.parse(hasAlerts);
      setHasNotifications(parsed);
    }

    window.addEventListener("storage", checkUserAlerts);

    return () => {
      window.removeEventListener("storage", checkUserAlerts);
    };
  }, []);
  */

  return (
    showHeaderUi(location) && (
      <Container>
        <LogoBarContainer>
          <Header>
            <Link to="/">
              <Logo
                src={isTabletOrAbove ? APP_LOGO_TEXT : APP_LOGO_TEXT}
                alt=""
              />
            </Link>

            {!user && (
              <>
                <Hidden mobile>
                  <Button
                    variant="white"
                    width={"120px"}
                    onClick={() => {
                      requireSignUp();
                    }}
                    style={{ marginLeft: "-12px" }}
                  >
                    Sign Up
                  </Button>
                </Hidden>

                <Hidden mobile>
                  <Button
                    variant="white"
                    width={"120px"}
                    onClick={() => {
                      requireLogin();
                    }}
                    style={{ marginLeft: "-12px" }}
                  >
                    Log In
                  </Button>
                </Hidden>

                {/*
                <Link
                  to="javascript:void(0)"
                  onClick={() =>
                    window.open(`mailto:${APP_CONTACT_EMAIL}`, "_blank")
                  }
                >
                  <Button variant="white" rounded="slight">
                    {APP_CONTACT_EMAIL}
                  </Button>
                </Link>
                */}

                <Hidden tablet desktop>
                  <Link to="/login">
                    <Button variant="white" width={"120px"}>
                      Log In
                    </Button>
                  </Link>
                </Hidden>
              </>
            )}

            {user && (
              <Dropdown
                menu={{
                  items,
                }}
                trigger={["click"]}
                dropdownRender={(menu) => {
                  return <StyledDropdownMenu>{menu}</StyledDropdownMenu>;
                }}
              >
                <ProfileButton
                  variant="white"
                  rounded="round"
                  style={({ isActive }) => ({
                    border: isActive && "2px solid var(--color-gray-100)",
                    borderRadius: isActive && "50%",
                  })}
                  onClick={(e) => e.preventDefault()}
                >
                  <a onClick={(e) => e.preventDefault()}>
                    <ProfilePic src={userInfo?.pfp ?? profileIcon} alt="" />
                  </a>
                </ProfileButton>
              </Dropdown>
            )}
          </Header>
        </LogoBarContainer>

        <NavContainer>
          <NavHeader>
            <NavItem
              to="/"
              style={({ isActive }) => ({
                fontWeight: isActive && 700,
                color: isActive && "var(--color-highlight)",
              })}
              onClick={clearBackLocations}
            >
              Home
            </NavItem>

            <NavItem
              to="/presto"
              style={({ isActive }) => ({
                fontWeight: isActive && 700,
                color: isActive && "var(--color-highlight)",
              })}
              onClick={clearBackLocations}
            >
              Use {APP_NAME}
            </NavItem>

            <NavItem
              to="/faq"
              style={({ isActive }) => ({
                fontWeight: isActive && 700,
                color: isActive && "var(--color-highlight)",
              })}
              onClick={clearBackLocations}
            >
              {/* {isTabletOrAbove ? "Frequently Asked Questions" : "FAQ"} */}
              FAQ
            </NavItem>

            <NavItem
              to="/contact"
              style={({ isActive }) => ({
                fontWeight: isActive && 700,
                color: isActive && "var(--color-highlight)",
              })}
              onClick={clearBackLocations}
            >
              {isTabletOrAbove ? "Contact Us" : "Contact"}
            </NavItem>

            {!user && (
              <>
                <Hidden mobile style={{ marginRight: "-100px" }}>
                  {/*
                <Button
                  width={"120px"}
                  onClick={() => {
                    openSignup();
                  }}
                  style={{ marginRight: "-20px" }}
                >
                  Sign Up
                </Button>
                */}
                </Hidden>
              </>
            )}
          </NavHeader>
        </NavContainer>

        <FeedDrawer
          isOpen={feedDrawerVisible}
          onClose={() => setFeedDrawerVisible(false)}
        />

        <EditModal
          isOpen={isEditParamSet && !isMobile}
          onClose={() => removeEditParam()}
        />

        <SettingsModal
          isOpen={isSettingsParamSet && !isMobile}
          onClose={() => removeSettingsParam()}
        />
      </Container>
    )
  );
}

const Container = styled.div`
  z-index: 1;
  width: 100%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
`;

const LogoBarContainer = styled.div`
  z-index: 1;
  width: 100%;
  height: 90px;
  position: relative;
  background: var(--color-primary);
`;

const NavContainer = styled.div`
  z-index: 1;
  background-color: white;
  width: 100%;
  position: relative;
`;

const Header = styled.div`
  display: flex;
  background-color: ${(props) => props.$isOnEdit && "var(--color-primary)"};
  padding-left: 22px;
  padding-right: 22px;
  height: 80px;
  padding-top: 8px;
  align-items: center;
  justify-content: space-between;

  @media (${device.tablet}) {
    justify-content: end;
    height: 80px;
    gap: 2rem;
    padding-right: 2.5rem;
  }

  @media (${device.desktop}) {
    justify-content: end;
    height: 80px;
    gap: 2rem;
    padding-right: 2.5rem;
  }
`;

const NavHeader = styled.div`
  display: flex;
  background-color: ${(props) => props.$isOnEdit && "var(--color-primary)"};
  padding-left: 22px;
  padding-right: 22px;
  height: 60px;
  padding-top: 8px;
  align-items: center;
  justify-content: space-between;

  @media (${device.tablet}) {
    justify-content: center;
    gap: 2rem;
    padding-right: 2.5rem;
  }

  @media (${device.desktop}) {
    justify-content: center;
    gap: 2rem;
    padding-right: 2.5rem;
  }
`;

const Logo = styled.img`
  top: 26px;
  left: 36px;
  display: block;
  width: auto;
  height: 34px;

  @media ${device.desktop}, ${device.tablet} {
    position: absolute;
  }

  @media (${device.mobile}) {
    height: 30px;
  }
`;

const NavItem = styled(NavLink)`
  font-weight: 500;
  color: var(--color-gray-100);
  font-family: var(--font-primary);
  font-size: 16px;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: fit-content;
`;

const ProfileButton = styled(NavLink)`
  height: 44px;
  width: 44px;

  img {
    height: 100%;
    width: 100%;
    background: white;
    border-radius: 50%;
    padding: 0px;
  }
`;

const ProfilePic = styled.img`
  object-fit: cover;
  object-position: center;
`;

const StyledButton = styled(Button)`
  padding-left: 18px;
  padding-right: 18px;
  text-transform: capitalize;
`;

const StyledDropdownMenu = styled.div`
  max-height: 400px;
  overflow: auto;
  border-radius: 8px;
  box-shadow: 0px 4px 12.4px 0px rgba(21, 21, 21, 0.74);

  .ant-dropdown-menu {
    border: 0 !important;
    background: white !important;

    a {
      color: black !important;
    }

    li:hover {
      background-color: var(--color-gray-50) !important;
    }

    .ant-dropdown-menu-item-divider {
      background-color: rgba(0, 0, 0, 0.1) !important;
    }
  }
`;

const StyledLink = styled(Link)`
  @media ${device.tablet}, ${device.desktop} {
    width: 100%;
  }
`;
