import { getAuth } from "firebase/auth";
import { alertTable } from "../../helpers/tables";
import { ALERT_AMOUNT_PER_PAGE } from "../../helpers/constants";
import { queryOne, queryMany, count } from "./all";
import {
  deleteDoc,
  doc,
  getFirestore,
  updateDoc,
  query,
  collection,
  getDocs,
  limit,
  orderBy,
  where,
  startAfter,
} from "firebase/firestore";
import { db, getResultsFromSnapshot } from "../../helpers/firebase";

const table = alertTable;
let documentSnapshots = null;

const alertModel = {
  count: async (...args) => {
    const data = await count({
      table,
      conditions: [...args],
    });

    return data;
  },
  delete: async (id) => {
    const user = getAuth().currentUser;
    if (!user) throw new Error("User not logged in");

    await deleteDoc(doc(getFirestore(), table, id));
  },
  getMany: async (...args) => {
    const data = await queryMany({
      table,
      conditions: [...args],
    });

    return data;
  },
  getOneById: async (id) => {
    const data = await queryOne({ table, id });
    return data;
  },
  getPage: async (pageNumber) => {
    const user = getAuth().currentUser;
    if (!user) throw new Error("User not logged in");

    const lastVisible =
      pageNumber === 0
        ? undefined
        : documentSnapshots?.docs[documentSnapshots.docs.length - 1];

    const q = lastVisible
      ? query(
          collection(db, table),
          where("user", "==", user.uid),
          orderBy("createdAt", "desc"),
          limit(ALERT_AMOUNT_PER_PAGE),
          startAfter(lastVisible)
        )
      : query(
          collection(db, table),
          where("user", "==", user.uid),
          orderBy("createdAt", "desc"),
          limit(ALERT_AMOUNT_PER_PAGE)
        );

    const querySnapshot = await getDocs(q);
    const results = await getResultsFromSnapshot(querySnapshot);
    documentSnapshots = querySnapshot;

    return results;
  },
  setAllRead: async () => {
    const user = getAuth().currentUser;
    if (!user) throw new Error("User not logged in");

    const unseenNotifications = await alertModel.getMany(
      ["user", "==", user.uid],
      ["seen", "==", false]
    );

    if (unseenNotifications.length > 0) {
      unseenNotifications.forEach(async (notif) => {
        const docRef = doc(db, table, notif.id);
        if (docRef) {
          await updateDoc(docRef, {
            seen: true,
          });
        }
      });
    }

    return;
  },
};

export default alertModel;
