export function getMixpanelAlertType(alert) {
  if (alert.type === "comment") {
    if (alert.replyTo === "") {
      return "Comment Submission";
    } else {
      return "Comment Reply";
    }
  } else if (alert.type === "episode") {
    return "New Episode";
  } else if (alert.type === "like") {
    if (alert.context.itemType === "comment") {
      return "Comment Like";
    } else if (alert.context.itemType === "submission") {
      return "Submission Like";
    }
  } else if (alert.type === "poll") {
    return "Creator Poll";
  } else if (alert.type === "quest") {
    if (alert.state === "results") {
      return "Results";
    } else if (alert.state === "submit") {
      return "New Quest";
    } else if (alert.state === "vote") {
      return "Vote";
    }
  } else if (alert.type === "storyworld") {
    return "New Webcomic";
  }

  return "";
}
