import CustomModal from "../CustomModal";
import EditProfileForm from "./EditProfileForm";

const EditModal = ({ isOpen, onClose }) => {
  return (
    <>
      <CustomModal
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
        isCentered={true}
        width={400}
        showCloseIcon={false}
      >
        <EditProfileForm />
      </CustomModal>
    </>
  );
};

export default EditModal;
