import React from "react";
import { Footer as _Footer } from "antd/es/layout/layout";
import {
  appHelpEmail,
  COMPANY_NAME,
  APP_LOGO_TEXT,
  FULL_COMPANY_NAME,
} from "../helpers/constants";
import { showFooterUi } from "../helpers/general";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { device } from "../lib/breakpoints";

export default function NavFooter() {
  const location = useLocation();

  return (
    showFooterUi(location) && (
      <Footer>
        <SocialContainer>
          <Link to="/" rel="noreferrer">
            <Logo src={APP_LOGO_TEXT} alt="" />
          </Link>
        </SocialContainer>

        <OtherContainer>
          <FooterLinks>
            <Link
              to="/contact"
              className={
                location.pathname.includes("/contact") ? "selected" : ""
              }
            >
              Contact
            </Link>{" "}
            |{" "}
            <Link
              to="/terms-of-use"
              className={
                location.pathname.includes("/terms-of-use") ? "selected" : ""
              }
            >
              Terms
            </Link>{" "}
            |{" "}
            <Link
              to="/privacy"
              className={
                location.pathname.includes("/privacy") ? "selected" : ""
              }
            >
              Privacy
            </Link>
            {/*
            {" "}
            |{" "}
            <Link
              to="/guidelines"
              className={
                location.pathname.includes("/guidelines") ? "selected" : ""
              }
            >
              Guidelines
            </Link>
            */}
          </FooterLinks>
          <CopyrightText>© {FULL_COMPANY_NAME}</CopyrightText>
        </OtherContainer>
      </Footer>
    )
  );
}

const Footer = styled(_Footer)`
  padding-bottom: 1.5rem;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: var(--color-primary);
  z-index: 2 !important;
  text-align: center;
  display: block;
  justify-content: center;
  text-align: -webkit-center;

  @media (${device.tablet}) {
    padding-top: 2rem;
    padding-bottom: 10px;
  }

  @media (${device.desktop}) {
    padding-top: 2rem;
    padding-bottom: 10px;
  }
`;

const CopyrightText = styled.div`
  font-size: 10px;
  font-weight: 500;
  color: #ede5e2;
  line-height: 32px;
  font-style: normal;
  line-height: 32.5px;
  margin-top: -8px;
`;

const FooterLinks = styled.div`
  margin-top: 10px;
  font-size: 10px;
  text-align: center;
  font-style: normal;
  color: var(--color-cream-dark);
  font-weight: 400;
  line-height: 32.5px;
  letter-spacing: 0.4px;

  a {
    color: rgba(255, 255, 255, 0.72);
  }

  .selected {
    color: var(--color-highlight);
    font-weight: 700;
  }
`;

const Logo = styled.img`
  height: 28px;
  width: auto;
`;

const OtherContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ede5e2;

  @media (${device.tablet}) {
    padding-bottom: 1rem;
  }

  @media (${device.desktop}) {
    padding-bottom: 1rem;
  }
`;

const SocialContainer = styled.div`
  @media ${device.desktop} {
    width: 908px;
    margin: auto;
  }
`;
