import { getAuth } from "firebase/auth";
import { queryOne, queryMany } from "./all";
import { commentTable } from "../../helpers/tables";
import {
  doc,
  setDoc,
  query,
  collection,
  getDocs,
  limit,
  orderBy,
  startAfter,
} from "firebase/firestore";
import { db, getResultsFromSnapshot } from "../../helpers/firebase";

const table = commentTable;
let documentPaginationSnapshots = null;

const commentModel = {
  create: async (props) => {
    const user = getAuth().currentUser;
    if (!user) throw new Error("User not logged in");

    const { comment, commentedBy, replyTo, sourceType, sourceId } = props;
    const body = JSON.stringify({
      comment,
      commentedBy,
      replyTo: replyTo ? replyTo.id : "",
      creator: user.uid,
      context: {
        sourceType,
        sourceId,
      },
    });

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/comments/post`,
      {
        method: "POST",
        headers: {
          accept: "application/json",
          "content-type": "application/json",
        },
        body,
      }
    );

    if (!response.ok) {
      throw new Error("Failed to post comment");
    }

    return response.json();
  },
  delete: async (id) => {
    const user = getAuth().currentUser;
    if (!user) throw new Error("User not logged in");

    const body = JSON.stringify({
      commentId: id,
    });

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/comments/delete`,
      {
        method: "POST",
        headers: {
          accept: "application/json",
          "content-type": "application/json",
        },
        body,
      }
    );

    if (!response.ok) {
      throw new Error("Error deleting comment");
    }

    return response.json();
  },
  edit: async (props) => {
    const user = getAuth().currentUser;
    if (!user) throw new Error("User not logged in");

    const { comment, editedComment } = props;
    const body = JSON.stringify({
      comment,
      editedCommentId: editedComment.id,
    });

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/comments/edit`,
      {
        method: "POST",
        headers: {
          accept: "application/json",
          "content-type": "application/json",
        },
        body,
      }
    );

    if (!response.ok) {
      throw new Error("Error editing comment");
    }

    return response.json();
  },
  getOneById: async (id) => {
    const data = await queryOne({ table, id });
    return data;
  },
  getMany: async (...args) => {
    const data = await queryMany({
      table,
      conditions: [...args],
    });

    return data;
  },
  getPage: async (pageNumber, pageSize) => {
    const user = getAuth().currentUser;
    if (!user) throw new Error("User not logged in");

    const lastVisible =
      pageNumber === 1
        ? undefined
        : documentPaginationSnapshots?.docs[
            documentPaginationSnapshots.docs.length - 1
          ];

    const q = lastVisible
      ? query(
          collection(db, table),
          orderBy("createdAt", "desc"),
          limit(pageSize),
          startAfter(lastVisible)
        )
      : query(
          collection(db, table),
          orderBy("createdAt", "desc"),
          limit(pageSize)
        );

    const querySnapshot = await getDocs(q);
    const results = await getResultsFromSnapshot(querySnapshot);
    documentPaginationSnapshots = querySnapshot;

    return results;
  },
  set: async (props) => {
    const user = getAuth().currentUser;
    if (!user) throw new Error("User not logged in");

    const {
      comment,
      commentedBy,
      commentId,
      creator,
      createdAt,
      edited,
      replyTo,
      sourceType,
      sourceId,
    } = props;

    const body = JSON.stringify({
      comment,
      commentedBy,
      commentId,
      creator,
      createdAt,
      edited,
      replyTo: replyTo ? replyTo.id : "",
      context: {
        sourceType,
        sourceId,
      },
    });

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/comments/set`,
      {
        method: "POST",
        headers: {
          accept: "application/json",
          "content-type": "application/json",
        },
        body,
      }
    );

    if (!response.ok) {
      throw new Error("Error posting comment");
    }

    return response.json();
  },
  undelete: async (comment) => {
    const user = getAuth().currentUser;
    if (!user) throw new Error("User not logged in");

    await setDoc(doc(db, commentTable, comment.id), {
      ...comment,
    });
  },
  count: async () => {
    const q = query(collection(db, table));
    const querySnapshot = await getDocs(q);
    return querySnapshot.size;
  },
};

export default commentModel;
