// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const sandboxFirebaseConfig = {
  apiKey: "AIzaSyA4UWh2rgOFsWlPBiL80JRFYlZDDfraBPE",
  authDomain: "prestohair-42-sandbox.firebaseapp.com",
  projectId: "prestohair-42-sandbox",
  storageBucket: "prestohair-42-sandbox.firebasestorage.app",
  messagingSenderId: "687146501147",
  appId: "1:687146501147:web:99f935bffd03e724d7e4be",
  measurementId: "G-HRCVB7CWNT",
};

export const prodFirebaseConfig = {
  apiKey: "AIzaSyCUarfLs3UeAw0nHSvHR0XKQ9LWEiZI8r0",
  authDomain: "prestohair-42.firebaseapp.com",
  projectId: "prestohair-42",
  storageBucket: "prestohair-42.firebasestorage.app",
  messagingSenderId: "128191909631",
  appId: "1:128191909631:web:c2d5bbb9533bc663cf06ca",
  measurementId: "G-9GEYW9JYB1",
};
