import { SUBMISSION_TYPES } from "../../helpers/constants";

// Can accept either the whole submission or just the submissionType
const SubmissionFilter = ({
  submission,
  type,
  idea,
  text,
  genAiArt,
  fallback,
}) => {
  const submissionType = type || getSubmissionType(submission);

  let component = null;
  switch (submissionType) {
    case SUBMISSION_TYPES.IDEA:
      component = idea;
      break;
    case SUBMISSION_TYPES.TEXT:
      component = text;
      break;
    // TODO: remove after ai is gone (equal to "image")
    case SUBMISSION_TYPES.GEN_AI:
      component = genAiArt;
      break;
    default:
      component = null;
      break;
  }

  return component || fallback;
};

// Can probably be removed (like with getQuestType) when the type properties exist in Firebase
/**
 * Returns the submissionType of the submission
 * @param {any} submission The whole submission object
 * @returns {("idea" | "text" | "image" | null)}
 */
export const getSubmissionType = (submission) => {
  if (submission?.type) {
    return submission.type;
  }

  // Until now, image's inclusion guarentees it was an ai submission
  if (submission?.imageUrl) {
    return SUBMISSION_TYPES.GEN_AI;
  } else if (submission?.text) {
    return SUBMISSION_TYPES.TEXT;
  }

  return null;
};

/**
 * Returns the matching value passed in for the current submissionType
 * @param {any} submission The whole submission object
 * @param {{ idea: any, text: any, image: any}} optionsByType An object with keys for each submissionType and values to return
 * @param {any} fallback A value to return if the submissionType is not found or a matching value isn't provided
 * @returns {any}
 */
export const valueBySubmissionType = (submission, optionsByType, fallback) => {
  const submissionType = getSubmissionType(submission);

  const value = optionsByType?.[submissionType];
  return value || fallback;
};

export default SubmissionFilter;
