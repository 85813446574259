import React from "react";
import { Username } from "../user";
import moment from "moment";
import {
  AlertContainer,
  AlertDescription,
  AlertIcon,
  AlertTextContainer,
  AlertText,
  AlertTitle,
} from "../../styles/CommonAlertElements";

export default function CommentAlert({ alert, ...props }) {
  return (
    <AlertContainer {...props}>
      <AlertIcon alert={alert} userId={alert.commentedBy} />
      <AlertTextContainer>
        <AlertTitle>
          <Username userId={alert.commentedBy} />{" "}
          {alert.replyTo && "replied to your comment"}
          {!alert.replyTo &&
            `commented on your ${alert.context.sourceType}`}{" "}
          <AlertText>
            • {moment(alert.createdAt.toDate()).fromNow(true)}
          </AlertText>
        </AlertTitle>
        <AlertDescription>{alert.comment}</AlertDescription>
      </AlertTextContainer>
    </AlertContainer>
  );
}
