import { getAuth } from "firebase/auth";
import { db, getResultsFromSnapshot } from "../../helpers/firebase";
import { episodesTable } from "../../helpers/tables";
import { count, queryMany, queryOne } from "./all";
import {
  collection,
  deleteDoc,
  query,
  setDoc,
  addDoc,
  where,
  updateDoc,
  orderBy,
  limit,
  getDocs,
  doc,
} from "firebase/firestore";

const table = episodesTable;

async function getPreviousPublishedEpNumber(episodeIndex, storyworldId) {
  const previousEpisodesQuery = query(
    collection(db, episodesTable),
    where("storyworldId", "==", storyworldId),
    where("isDraft", "==", false),
    where("episodeIndex", "<", episodeIndex),
    orderBy("episodeIndex", "desc"),
    limit(1)
  );
  const previousEpisodesSnapshot = await getDocs(previousEpisodesQuery);
  const previousEpisodesResults = getResultsFromSnapshot(
    previousEpisodesSnapshot
  );

  const previouslyPublishedEpisode = previousEpisodesResults[0];
  return previouslyPublishedEpisode?.episodeNumber;
}

async function updateEpisodeNumbers(currentIndex, storyworldId, episodeAdded) {
  const upcomingEpisodesQuery = query(
    collection(db, episodesTable),
    where("storyworldId", "==", storyworldId),
    where("isDraft", "==", false),
    where("episodeIndex", ">", currentIndex),
    orderBy("episodeIndex", "asc")
  );
  const upcomingEpisodesSnapshot = await getDocs(upcomingEpisodesQuery);
  const upcomingResults = getResultsFromSnapshot(upcomingEpisodesSnapshot);

  return Promise.all(
    upcomingResults.map(async (episode) => {
      await updateDoc(doc(db, episodesTable, episode.id), {
        episodeNumber: episodeAdded
          ? episode.episodeNumber + 1
          : episode.episodeNumber - 1,
      });
    })
  );
}

async function decrementIndexNumbers(currentIndex, storyworldId) {
  const upcomingEpisodesQuery = query(
    collection(db, episodesTable),
    where("storyworldId", "==", storyworldId),
    where("episodeIndex", ">", currentIndex),
    orderBy("episodeIndex", "asc")
  );
  const upcomingEpisodesSnapshot = await getDocs(upcomingEpisodesQuery);
  const upcomingEpisodesResults = getResultsFromSnapshot(
    upcomingEpisodesSnapshot
  );

  return Promise.all(
    upcomingEpisodesResults.map(async (episode) => {
      await updateDoc(doc(db, episodesTable, episode.id), {
        episodeIndex: episode.episodeIndex - 1,
      });
    })
  );
}

const episodeModel = {
  getOneById: async (id) => {
    const data = await queryOne({ table, id });
    return data;
  },
  getMany: async (...args) => {
    const data = await queryMany({
      table,
      conditions: [...args],
    });

    return data;
  },
  getManyByStoryworldId: async (storyworldId, ...args) => {
    const data = await queryMany({
      table,
      conditions: [["storyworldId", "==", storyworldId], ...args],
    });

    return data;
  },
  create: async (data, currentNumberOfEpisodes) => {
    const currentUser = getAuth().currentUser;
    if (!currentUser) throw new Error("User not logged in");

    const { title, thumbnailUrl, storyworldId, ...rest } = data;

    const episodeIndex = currentNumberOfEpisodes + 1;

    const previousEpisodeNumber = await getPreviousPublishedEpNumber(
      episodeIndex,
      storyworldId
    );

    const docRef = await addDoc(collection(db, episodesTable), {
      ...rest,
      creator: currentUser.uid,
      title: title ?? "New Storyworld",
      createdAt: new Date(),
      lastUpdated: new Date(),
      views: 0,
      thumbnailUrl: thumbnailUrl ?? "",
      storyworldId,
      episodeIndex,
      episodeNumber: previousEpisodeNumber ? previousEpisodeNumber + 1 : 1,
    });

    if (!docRef) {
      return false;
    }

    return true;
  },
  update: async (episodeId, data, statusChanged = false) => {
    const currentUser = getAuth().currentUser;
    if (!currentUser) throw new Error("User not logged in");

    const {
      episodeIndex,
      episodeNumber,
      title,
      thumbnailUrl,
      isDraft,
      storyworldId,
      ...rest
    } = data;

    let updatedEpisodeNumber;
    // If the episode is being published, we need to increment the previous episode's number
    if (!isDraft && statusChanged) {
      const previousEpNumber = await getPreviousPublishedEpNumber(
        episodeIndex,
        storyworldId
      );
      updatedEpisodeNumber = previousEpNumber ? previousEpNumber + 1 : 1;
    }
    // If being converted into a draft we set the EpisodeNumber to null
    else if (isDraft && statusChanged) {
      updatedEpisodeNumber = null;
    }
    // If the status is untouched then so is the episodeNumber
    else {
      updatedEpisodeNumber = episodeNumber;
    }

    await setDoc(doc(db, episodesTable, episodeId), {
      ...rest,
      title: title ?? "Newly Updated Episode",
      creator: currentUser.uid,
      lastUpdated: new Date(),
      thumbnailUrl: thumbnailUrl ?? "",
      isDraft,
      storyworldId,
      episodeIndex,
      episodeNumber: updatedEpisodeNumber,
    });

    if (statusChanged) {
      await updateEpisodeNumbers(episodeIndex, storyworldId, !isDraft);
    }

    return true;
  },
  delete: async (episodeId) => {
    const episode = await queryOne({ table, id: episodeId });

    if (!episode) {
      throw new Error("Episode not found");
    }

    const { episodeIndex, storyworldId } = episode;

    await deleteDoc(doc(db, episodesTable, episodeId));
    await updateEpisodeNumbers(episodeIndex, storyworldId, false);
    await decrementIndexNumbers(episodeIndex, storyworldId);
  },
  count: async (...args) => {
    const data = await count({
      table,
      conditions: [...args],
    });

    return data;
  },
};

export default episodeModel;
