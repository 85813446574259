import React from "react";
import { Username } from "../user";
import moment from "moment";
import {
  AlertContainer,
  AlertDescription,
  AlertTextContainer,
  AlertText,
  AlertTitle,
  AlertIcon,
  AlertWatchIcon,
  AlertTimeLeft,
} from "../../styles/CommonAlertElements";

export default function CreatorPollAlert({ alert, ...props }) {
  return (
    <AlertContainer {...props}>
      <AlertIcon alert={alert} userId={alert.creator} />
      <AlertTextContainer>
        <AlertTitle>
          New creator poll from <Username userId={alert.creator} />
          <AlertText>
            {` • ${moment(alert.createdAt.toDate()).fromNow(true)}`}
          </AlertText>
        </AlertTitle>
        <AlertDescription>
          {alert.state !== "results" ? (
            <>
              <AlertWatchIcon src="/icons/quests/clock.svg" alt="time icon" />{" "}
              <AlertTimeLeft>
                {alert.endTime.toDate() > new Date()
                  ? moment(alert.endTime.toDate()).fromNow(true)
                  : "Closed"}
              </AlertTimeLeft>
              <AlertText>{` • ${alert.storyworldName}`}</AlertText>
            </>
          ) : (
            <AlertText>{alert.storyworldName}</AlertText>
          )}
        </AlertDescription>
      </AlertTextContainer>
    </AlertContainer>
  );
}
