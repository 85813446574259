import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import userModel from "../../../lib/firebase/userModel";
import { userKeys } from "../../../lib/queryKeys";
import CommentInputProvider, {
  CommentInputContext,
} from "../../../context/CommentInputProvider";
import AlertSourceContext, {
  SOURCE,
} from "../../../context/AlertSourceContext";
import { useBreakpoints } from "../../../lib/breakpoints";
import SubmissionSheet from "./SubmissionSheet";
import SubmissionModal from "./SubmissionModal";

export default function SubmissionPreview({
  submission,
  drawerOpen,
  setDrawerOpen,
  winner = false,
  isInVote = false,
}) {
  const { data: userInfo } = useQuery({
    queryKey: userKeys.user(submission?.creator),
    queryFn: () => {
      if (!submission?.creator) return null;

      return userModel.getOneById(submission && submission.creator);
    },
  });

  const [sortType, setSortType] = useState("top");

  const { isMobile } = useBreakpoints();

  const commentsEnabled =
    !isInVote && userInfo?.allowSubmissionComments !== false;

  return (
    submission && (
      <>
        <AlertSourceContext.Provider
          value={{
            sourceType: SOURCE.submission,
            sourceId: submission.id,
          }}
        >
          <CommentInputProvider>
            <CommentInputContext.Consumer>
              {({ isFocused }) =>
                isMobile ? (
                  <SubmissionSheet
                    submission={submission}
                    drawerOpen={drawerOpen}
                    setDrawerOpen={setDrawerOpen}
                    winner={winner}
                    isInVote={isInVote}
                    isFocused={isFocused}
                    commentsEnabled={commentsEnabled}
                    sortType={sortType}
                    setSortType={setSortType}
                  />
                ) : (
                  <SubmissionModal
                    submission={submission}
                    drawerOpen={drawerOpen}
                    setDrawerOpen={setDrawerOpen}
                    winner={winner}
                    isInVote={isInVote}
                    commentsEnabled={commentsEnabled}
                    sortType={sortType}
                    setSortType={setSortType}
                  />
                )
              }
            </CommentInputContext.Consumer>
          </CommentInputProvider>
        </AlertSourceContext.Provider>
      </>
    )
  );
}
