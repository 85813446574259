import React from "react";
import CustomSheet from "../CustomSheet";
import ProfileImageWizard from "./ProfileImageWizard";

export default function ProfileImageSheet({
  isOpen,
  setOpen,
  profilePic,
  setProfilePic,
}) {
  return (
    <CustomSheet
      isOpen={isOpen}
      onClose={() => setOpen(false)}
      snapPoints={[525]}
      tweenConfig={{ ease: "easeOut", duration: 0.3 }}
    >
      <ProfileImageWizard
        profilePic={profilePic}
        setProfilePic={setProfilePic}
        onAction={() => setOpen(false)}
      />
    </CustomSheet>
  );
}
