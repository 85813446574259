import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { alertConfigKeys } from "./queryKeys";
import alertConfigModel from "./firebase/alertConfigModel";
import { useContext, useEffect, useMemo } from "react";
import { AuthContext } from "../context/AuthProvider";

const useAlertConfig = (storyworldId) => {
  const { user } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const userId = user?.uid;

  const {
    data: config,
    isLoading,
    isFetched,
    isError,
  } = useQuery({
    queryKey: alertConfigKeys.user(userId),
    queryFn: async () => {
      return alertConfigModel.getOneById(userId);
    },
    enabled: !!userId,
    // This should only be changed by user action, so there should be no need to refetch in the background.
    staleTime: Infinity,
    gcTime: Infinity,
  });

  const { mutate: createConfig } = useMutation({
    mutationFn: async () => {
      return alertConfigModel.create();
    },
    onSuccess: (createdConfig) => {
      queryClient.setQueryData(alertConfigKeys.user(userId), createdConfig);
    },
  });

  const { mutate: updateConfig, variables: optimisticUpdate } = useMutation({
    mutationFn: async ({ settingId, value }) => {
      return alertConfigModel.update({
        id: config.id,
        settings: {
          [settingId]: value,
        },
      });
    },
    // https://tanstack.com/query/latest/docs/framework/react/guides/optimistic-updates#via-the-ui
    onSettled: async (updatedConfig) => {
      return await queryClient.setQueryData(
        alertConfigKeys.user(userId),
        updatedConfig
      );
    },
  });

  const { mutate: updateFollowing } = useMutation({
    mutationFn: async ({ alertType, followingStatus }) => {
      // For now emails and alerts are a package deal based on the status of the alerts.
      if (alertType === "episode") {
        const currentStatus = followingStatus.episodeAlerts;
        const episodeAlertStoryworlds = config.episodeAlertStories ?? [];
        const episodeEmailStoryworlds = config.episodeEmailStories ?? [];

        // Remove from lists
        if (currentStatus === true) {
          return alertConfigModel.update({
            id: config.id,
            settings: {
              episodeAlertStories: episodeAlertStoryworlds.filter(
                (followedStoryworldId) => followedStoryworldId !== storyworldId
              ),
              episodeEmailStories: episodeEmailStoryworlds.filter(
                (emailStoryworldId) => emailStoryworldId !== storyworldId
              ),
            },
          });
        }

        // Add to lists, using a set to prevent duplicates in case the email/alert states get out of sync
        return alertConfigModel.update({
          id: config.id,
          settings: {
            episodeAlertStories: [
              ...new Set([...episodeAlertStoryworlds, storyworldId]),
            ],
            episodeEmailStories: [
              ...new Set([...episodeEmailStoryworlds, storyworldId]),
            ],
          },
        });
      }

      // Quest Alerts
      const currentStatus = followingStatus.questAlerts;
      const questAlertStoryworlds = config.questAlertStories ?? [];

      // If currently following, then this action will add this storyworld to the unfollow list. Else remove from list by filtering it out.
      const value =
        currentStatus === true
          ? questAlertStoryworlds.filter(
              (followedStoryworldId) => followedStoryworldId !== storyworldId
            )
          : [...questAlertStoryworlds, storyworldId];

      return alertConfigModel.update({
        id: config.id,
        settings: {
          questAlertStories: value,
        },
      });
    },
    onSuccess: (updatedConfig) => {
      queryClient.setQueryData(alertConfigKeys.user(userId), updatedConfig);
    },
  });

  // TODO: remove this (or right a migration script first)
  // * This can be removed when all existing users have configs
  useEffect(() => {
    if (isFetched && !isError && !config) {
      createConfig();
    }
  }, [config, createConfig, isError, isFetched]);

  // Fold in the update immediately so the toggling of the Switch feels instant
  const optimisticConfig = useMemo(() => {
    return optimisticUpdate
      ? {
          ...config,
          [optimisticUpdate.settingId]: optimisticUpdate.value,
        }
      : config;
  }, [config, optimisticUpdate]);

  const followingStatus = useMemo(() => {
    if (!storyworldId || !config) {
      // For now no need to add an episodeEmails property because it is attached to the value of episodeAlerts
      return {
        questAlerts: false,
        episodeAlerts: false,
      };
    }

    const questAlerts =
      config.questAlertStories?.includes(storyworldId) ?? false;
    const episodeAlerts =
      config.episodeAlertStories?.includes(storyworldId) ?? false;

    return {
      questAlerts,
      episodeAlerts,
    };
  }, [config, storyworldId]);

  const toggleNotifications = ({ settingId, value }, mutationCallbacks) => {
    updateConfig({ settingId, value }, mutationCallbacks);
  };

  const toggleFollow = ({ alertType }, mutationCallbacks) => {
    updateFollowing({ alertType, followingStatus }, mutationCallbacks);
  };

  return {
    config: optimisticConfig,
    followingStatus,
    isLoading,
    toggleNotifications,
    toggleFollow,
  };
};

export default useAlertConfig;
