import styled from "styled-components";
import HomeMain from "./HomeMain";
import HomeFeatures from "./HomeFeatures";
import HomeContact from "./HomeContact";
import HomeBanner from "./HomeBanner";
import HomeCTA from "./HomeCTA";
import { Spacer } from "../../styles/CommonElements";

export default function Home() {
  return (
    <Container>
      <HomeMain />
      <HomeFeatures />
      <HomeBanner />
      <Spacer $marginTop="5rem" />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
`;
