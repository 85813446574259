import React from "react";
import { Username } from "../user";
import moment from "moment";
import {
  AlertContainer,
  AlertDescription,
  AlertTextContainer,
  AlertText,
  AlertTitle,
  AlertIcon,
} from "../../styles/CommonAlertElements";

export default function LikeAlert({ alert, ...props }) {
  return (
    <AlertContainer {...props}>
      <AlertIcon alert={alert} userId={alert.likedBy} />
      <AlertTextContainer>
        <AlertTitle>
          <Username userId={alert.likedBy} /> liked your{" "}
          {alert.context.itemType}
          <AlertText>
            {" "}
            • {moment(alert.createdAt.toDate()).fromNow(true)}
          </AlertText>
        </AlertTitle>
        {alert.context.itemType === "comment" && (
          <AlertDescription>{alert.comment}</AlertDescription>
        )}
      </AlertTextContainer>
    </AlertContainer>
  );
}
