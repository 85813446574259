export async function sendSupportMessage(data) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/sendSupportMessage`,
      {
        method: "POST",
        headers: {
          accept: "application/json",
          "content-type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error sending report:", error);
    throw error;
  }
}
