import "./Notification.css";
import "./styles.css";
import "./fonts.css";
import "./colors.css";
import "./globals.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { _queryClient } from "./lib/client";
import mixpanel from "mixpanel-browser";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import {
  Login,
  PrivacyPolicy,
  Profile,
  ResetPassword,
  TermsOfService,
  EditProfile,
  ChangePassword,
  About,
} from "./pages";
import { FAQ, Presto } from "./views";
import ProtectedRoute from "./components/ProtectedRoute";
import VisionHolderPanel from "./views/VisionHolderPanel";
import { ForgotPassword, SignUp } from "./components/login";
import { GOOGLE_OAUTH_CLIENT_ID } from "./helpers/authentication";
import AuthenticationRoute from "./components/AuthenticationRoute";
import { device } from "./lib/breakpoints";
import { Helmet } from "react-helmet";
import RouterErrorBoundary from "./views/RouterErrorBoundary";
import Home from "./pages/home/Home";
import { APP_NAME } from "./helpers/constants";
import Contact from "./views/Contact";
import Dashboard from "./pages/dashboard/Dashboard";

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
  debug: !process.env.NODE_ENV || process.env.NODE_ENV === "development",
  track_pageview: true,
  persistence: "localStorage",
  ignore_dnt: true,
});

const router = createBrowserRouter([
  {
    element: <Presto />,
    errorElement: <RouterErrorBoundary />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/privacy",
        element: <PrivacyPolicy />,
      },
      {
        path: "/reset-password",
        element: <ResetPassword />,
      },
      {
        path: "/terms-of-use",
        element: <TermsOfService />,
      },
      {
        path: "/faq",
        element: <FAQ />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
      {
        // This is just to check that you have completed your profile
        element: <ProtectedRoute />,
        children: [
          // { path: "/stories", element: <Storyworlds /> },
          // { path: "/stories/:storyId", element: <StoryworldDetail /> },
          /*
          {
            path: "/stories/:storyId/episode/:epNum",
            element: <ComicReader />,
          },
          */
          // { path: "/genres", element: <Genres /> },
          // { path: "/quests", element: <Quests /> },
          // { path: "/quests/:storyId", element: <StoryworldQuestPage /> },
          // QuestDetail, Formerly /stories/${storyId}/quests/${questId}
          /*
          {
            path: "/quests/:storyId/quest/:questId",
            element: <StoryworldQuest />,
          },
          {
            path: "/quests/:storyId/quest/:questId/submit",
            element: <ContentCreation />,
          },
          {
            path: "/quests/:storyId/quest/:questId/vote",
            element: <PollVote />,
          },
          */
          /*
          { path: "/profile", element: <Profile /> },
          */
          { path: "/profile/edit", element: <EditProfile /> },
          { path: "/profile/change-password", element: <ChangePassword /> },
          // { path: "/account/:id", element: <UserProfile /> },
          // { path: "/feed", element: <Feed /> },
          // { path: "/feed/settings", element: <FeedSettings /> },
        ],
      },
      {
        path: "/login",
        element: (
          <AuthenticationRoute>
            <Login />
          </AuthenticationRoute>
        ),
      },
      /*
      {
        path: "/signup",
        element: (
          <AuthenticationRoute>
            <SignUp />
          </AuthenticationRoute>
        ),
      },
      */
      {
        path: "/forgot-password",
        element: (
          <AuthenticationRoute>
            <ForgotPassword />
          </AuthenticationRoute>
        ),
      },
      // Paths that require a user to be logged in
      {
        element: <ProtectedRoute requireAccount />,
        children: [{ path: "/presto", element: <Dashboard /> }],
      },
      {
        element: (
          <ProtectedRoute
            supportedBreakpoints={[device.tablet, device.desktop]}
            requireAdmin
          >
            <VisionHolderPanel />
          </ProtectedRoute>
        ),
        children: [
          /*
          { path: "/create", element: <Chat /> },
          { path: "/edit", element: <StoryworldSelector /> },
          { path: "/edit/promos", element: <PromoAdmin /> },
          { path: "/edit/promos/create", element: <CreatePromo /> },
          { path: "/edit/mod/comments", element: <CommentsModeration /> },
          */
        ],
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <>
    <Helmet>
      <title>{APP_NAME}</title>
    </Helmet>
    <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>
      <QueryClientProvider client={_queryClient}>
        <RouterProvider router={router} />
        <ReactQueryDevtools buttonPosition="bottom-left" />
      </QueryClientProvider>
    </GoogleOAuthProvider>{" "}
  </>
);
