import { APP_NAME } from "./constants";

const faqData = [
  {
    title: `What is ${APP_NAME}?`,
    description: `${APP_NAME} is the premiere platform for showing your patients what is possible with your team's hair restoration techniques. Currently, hair restoration providers use example images of past patients to help potential patients get an idea of what is possible. However, all patients' hair restoration needs and hair quality is unique, which is why we believe consultations should be personalized to each and every one. With ${APP_NAME}, you can show your patients a possibility of what you can achieve together while growing your practice and their hairline.\n`,
  },
  {
    title: `Who built ${APP_NAME}?`,
    description: `${APP_NAME} was created by hair restoration patients and AI experts who understand what it's like to sit in a consultation while thinking about the unknown results of a hair restoration procedure, or if they should even do it. Our mission is to help your patients take the next step with your team of professionals while having less anxiety and more faith in your team's capabilities, and their final results.\n`,
  },
  {
    title: `Can ${APP_NAME} predict my patients' exact hair growth?`,
    description: `${APP_NAME} is meant as a potential example of what your patients could experience after your hair restoration services. However, it is important to remind your patients that ${APP_NAME}'s services are just that: a prediction. Patient expectations should be managed based on their chosen procedure method and amount of hair grafts.\n`,
  },
  {
    title: `Is ${APP_NAME} suitable for all kinds of patients?`,
    description: `${APP_NAME} is designed for both men and women of various age groups, but it works best for individuals who are looking to assess how a hair restoration procedure may look in relation to their current hairline.\n`,
  },
  {
    title: `How secure is my patients' personal data on ${APP_NAME}?`,
    description: `We take your privacy seriously. All data entered into ${APP_NAME} is encrypted, and we adhere to strict data protection standards to ensure your patients' information is safe.\n`,
  },
];

export default faqData;
