import { getAuth } from "firebase/auth";
import { likesTable } from "../../helpers/tables";
import { count, queryMany } from "./all";

const table = likesTable;

const likeModel = {
  create: async (props) => {
    const user = getAuth().currentUser;
    if (!user) throw new Error("User not logged in");

    const { itemType, itemId, sourceType, sourceId } = props;

    const body = JSON.stringify({
      creator: user.uid,
      context: {
        itemId,
        itemType,
        sourceId,
        sourceType,
      },
    });

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/like`, {
        method: "POST",
        headers: {
          accept: "application/json",
          "content-type": "application/json",
        },
        body,
      });

      return await response.json();
    } catch (error) {
      console.error("Error posting comment:", error);
      throw error;
    }
  },
  delete: async (props) => {
    const user = getAuth().currentUser;
    if (!user) throw new Error("User not logged in");

    const { likeId } = props;
    const body = JSON.stringify({
      likeId,
    });

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/unlike`, {
        method: "POST",
        headers: {
          accept: "application/json",
          "content-type": "application/json",
        },
        body,
      });

      return await response.json();
    } catch (error) {
      console.error("Error posting comment:", error);
      throw error;
    }
  },
  getMany: async (...args) => {
    const data = await queryMany({
      table,
      conditions: [...args],
    });

    return data;
  },
  getManyByStoryworldId: async (storyworldId, ...args) => {
    const data = await queryMany({
      table,
      conditions: [["storyworldId", "==", storyworldId], ...args],
    });

    return data;
  },
  count: async (...args) => {
    const data = await count({
      table,
      conditions: [...args],
    });

    return data;
  },
};

export default likeModel;
