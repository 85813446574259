import { createContext, useContext } from "react";

// At the moment these are the only sources we want to support
export const SOURCE = {
  submission: "submission",
  episode: "episode",
  quest: "quest",
};

// The intent of this context is to provide contextual information to our backend about where a comment or like is taking place.
// As we don't distinguish the location of a submission because they can be viewed in quests, profiles, the gallery, etc, it gets its own category here.
const AlertSourceContext = createContext();

// Just an option to have a nicer error (and be alerted if you aren't destructuring and your context is undefined)
export const useAlertSourceContext = () => {
  const ctx = useContext(AlertSourceContext);

  if (!ctx) {
    throw new Error("Missing AlertSourceContext Provider");
  }

  return ctx;
};

export default AlertSourceContext;
