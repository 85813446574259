import React from "react";
import moment from "moment";
import {
  AlertContainer,
  AlertStoryIcon,
  AlertTextContainer,
  AlertText,
  AlertTitle,
} from "../../styles/CommonAlertElements";

export default function StoryworldAlert({ alert, ...props }) {
  return (
    <AlertContainer {...props}>
      <AlertStoryIcon>
        <img src={alert.alertImage} />
      </AlertStoryIcon>
      <AlertTextContainer>
        <AlertTitle>
          {`A new webcomic has landed! Check out episode 1 of ${alert.storyworldName}`}
          <AlertText>{` • ${moment(alert.createdAt.toDate()).fromNow(
            true
          )}`}</AlertText>
        </AlertTitle>
      </AlertTextContainer>
    </AlertContainer>
  );
}
