import { Image } from "antd";
import { Link } from "react-router-dom";
import date from "date-and-time";

// General Info
export const COMPANY_NAME = "Junoware";
export const FULL_COMPANY_NAME = "Junoware";
export const APP_NAME = "NewYuu";
export const APP_NAME_LOWER = APP_NAME.toLocaleLowerCase();
export const APP_LOGO = "";
export const APP_LOGO_TEXT = "";
// export const APP_LOGO = "/images/logos/logo.svg";
// export const APP_LOGO_TEXT = "/images/logos/logo-text-2.svg";

// URL Info
export const APP_URL = "newyuu.com";

// Email Info
export const appHelpEmail = `hello@${APP_URL}`;
export const APP_SUPPORT_EMAIL = `support@${APP_URL}`;
export const APP_CONTACT_EMAIL = `contact@${APP_URL}`;

// Service Info
export const PERSONA_TERM = "persona";
export const adminUserId = "kKDOmz9HyQW6XwaufxZkvmnNeF02";

export const galleryPageLimit = 6;
export const commentLengthThreshold = 1;
export const commentLengthMax = 500;

export const spinnerIcon = "/icons/spinner.svg";

export const defaultArtQuestCard = "";
export const defaultWritingQuestCard = "";
export const defaultVoteCard = "";
export const defaultArtQuestFull = "";
export const defaultWritingQuestFull = "";
export const defaultVoteFull = "";

export const tikTokLink = `https://www.tiktok.com/@${APP_NAME_LOWER}`;
export const instagramLink = `https://www.instagram.com/${APP_NAME_LOWER}/`;
export const twitterLink = `https://twitter.com/${APP_NAME_LOWER}`;
export const discordLink = "https://discord.gg/";

export const datePattern = date.compile("ddd, MMM DD");
export const questCardDatePattern = date.compile("MMMM DD, YYYY");

export const noHeaderUiPaths = [
  "/login",
  "/signup",
  "/forgot-password",
  "/reset-password",
];

export const noFooterUiPaths = ["forgot-password", "/reset-password"];

export const ERROR_PROFANITY =
  "Oops! Please use kind words. Let's keep our community positive and respectful ✌️";
export const usernameTakenErrorText = "That username is taken";
export const passwordErrorText = "Wrong password";

// The values here are used as keys in the Firebase table. Ye be warned when a-changing them.
export const configs = {
  likeYourComment: "like-your-comment",
  replyYourComment: "reply-your-comment",
  likeYourSubmission: "like-your-submission",
  replyYourSubmission: "reply-your-submission",
  newWebcomic: "new-webcomic",
  newEpisode: "new-episode",
  newQuest: "new-quest",
  questVotingLive: "quest-voting-live",
  resultsAnnounced: "results-announced",
  creatorPollLive: "creator-poll-live",
};

export const LOCAL_STORAGE = {
  hasAlerts: "has-alerts",
};
export const SESSION_STORAGE = {
  redirectUrl: "redirect-url",
};

export const ONE_DAY_FROM_NOW = new Date(
  new Date().getTime() + 60 * 60 * 24 * 1000
);

export const TWO_DAYS_FROM_NOW = new Date(
  new Date().getTime() + 60 * 60 * 48 * 1000
);

export const ALERT_AMOUNT_PER_PAGE = 15;

export const QUEST_TYPES = {
  VISUAL: "visual",
  WRITING: "writing",
  // TODO: remove after ai is gone
  IMAGE: "image",
};

export const SUBMISSION_TYPES = {
  IDEA: "idea",
  TEXT: "text",
  // TODO: remove after ai is gone
  GEN_AI: "image",
};

export const striderAdminList = ["bryan@junoware.com"];

export const defaultProfileImages = [];

export const adminMenuItems = [
  {
    label: (
      <Image
        style={{ display: "flex !important" }}
        src="/icons/book.svg"
        preview={false}
      />
    ),
    disabled: true,
  },
  {
    label: <Link to="/stories">Stories</Link>,
    key: "/stories",
  },
  {
    label: <Link to="/quests">Quests</Link>,
    key: "/quests",
  },
  {
    label: <Link to="/art">Art</Link>,
    key: "/art",
  },
  {
    label: <Link to="/profile">Profile</Link>,
    key: "/profile",
  },
];

export function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

export const generalQuillModules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      {
        color: [
          "#FFF6F2",
          "#EC55AB",
          "#F5A2FF",
          "#F6B438",
          "#EDE590",
          "#3DDE9C",
          "#4DADF9",
          "#7586FF",
        ],
      },
    ],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    ["clean"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
  imageCompress: {
    quality: 0.5,
    maxWidth: 10000, // default
    maxHeight: 10000, // default
    // imageType: "image/jpeg", // default
    debug: false, // default
    suppressErrorLogging: false, // default
    insertIntoEditor: undefined, // default
    /* 
    insertIntoEditor: async (imageBase64URL, imageBlob) => {
      const downloadUrl = await uploadImage("questImages", imageBlob);
      console.log(downloadUrl);
      return imageBlob;
    },
    */
  },
  imageResize: {
    modules: ["Resize", "DisplaySize"],
    handleStyles: {
      backgroundColor: "white",
      border: "none",
      color: "white",
    },
  },
};

export const createPollQuillModules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      {
        color: [
          "#FFF6F2",
          "#EC55AB",
          "#F5A2FF",
          "#F6B438",
          "#EDE590",
          "#3DDE9C",
          "#4DADF9",
          "#7586FF",
        ],
      },
    ],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    ["clean"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
  imageCompress: {
    quality: 0.8,
    maxWidth: 1000,
    maxHeight: 1000,
    imageType: "image/jpeg",
    debug: false,
    suppressErrorLogging: false,
    insertIntoEditor: undefined,
  },
  imageResize: {
    modules: ["Resize", "DisplaySize"],
    handleStyles: {
      backgroundColor: "white",
      border: "none",
      color: "white",
    },
  },
};

export const createCreatorPollQuillModules = {
  toolbar: [["bold", "italic", "underline", "emoji", { list: "bullet" }]],
  "emoji-toolbar": true,
  "emoji-shortname": true,
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};

export const vhPanelLeftPanelWidth = 360;
export const vhPanelrightPanelLeftMargin = 10;

export const storyworldTypes = {
  ARCHIVED: "archived",
  CATALOG: "catalog",
  STORYWORLD: "storyworld",
};

// dropdown is set by antd currently at 1050
export const zIndex = {
  sheet: 999,
  authSheet: 1000,
};

export const maxIdeaBlockEntries = 3;

export const PROMO_STATUS = {
  ACTIVE: "active",
  EXPIRED: "expired",
  SCHEDULED: "scheduled",
};

export const getPromoStatus = (promo) => {
  if (!promo) return "";

  const start = promo.startTimestamp;
  const end = promo.endTimestamp;

  if (start < Date.now() && end > Date.now()) {
    return PROMO_STATUS.ACTIVE;
  } else if (end < Date.now()) {
    return PROMO_STATUS.EXPIRED;
  }

  return PROMO_STATUS.SCHEDULED;
};

export const getPromoStatusColor = (promo) => {
  const status = getPromoStatus(promo);

  if (status === PROMO_STATUS.ACTIVE) {
    return "#70F2BA";
  } else if (status === PROMO_STATUS.EXPIRED) {
    return "var(--color-red-error)";
  }

  return "#D9D9D9";
};

export const MIN_USERNAME_LENGTH = 3;
