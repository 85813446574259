import { likesTable, submissionsTable } from "../../helpers/tables";
import { SUBMISSION_TYPES } from "../../helpers/constants";
import { db, getResultsFromSnapshot } from "../../helpers/firebase";
import { queryMany, queryOne, count, batchQuery } from "./all";
import { trackEvent } from "../../helpers/mixpanel";
import { getAuth } from "firebase/auth";
import {
  query,
  collection,
  getDocs,
  orderBy,
  where,
  startAfter,
  getCountFromServer,
  addDoc,
} from "firebase/firestore";

const table = submissionsTable;

const submissionModel = {
  create: async (props) => {
    const user = getAuth().currentUser;
    if (!user) throw new Error("User not logged in");

    const {
      type,
      quest,
      imageUrl = "",
      images = [],
      text,
      prompt = "",
      usedNegativePrompt = null,
      usedStarterPrompt = null,
      startTimestamp = new Date(),
      usedSeedImage = null,
      storyworld,
      title = "",
      hasSearchImage = false,
      hasUploadImage = false,
    } = props;

    const rejected = quest.ongoing ?? false;

    if (type === SUBMISSION_TYPES.GEN_AI) {
      await addDoc(collection(db, submissionsTable), {
        type,
        imageUrl,
        text,
        prompt,
        creator: user.uid,
        questId: quest.id,
        createdAt: new Date(),
        lastUpdated: new Date(),
        rejected,
      });
      trackEvent("Created Quest Submission", {
        quest_id: quest.id,
        quest_name: quest.title,
        storyworld_id: quest.storyworld,
        storyworld_name: storyworld.title,
        time_spent_seconds: Math.abs(new Date() - startTimestamp) / 1000,
        title: `${storyworld.title}: ${quest.title}`,
        type: "art",
        used_negative_prompt: usedNegativePrompt,
        used_seed_image: usedSeedImage,
        used_starter_prompt: usedStarterPrompt,
      });
    } else {
      if (type === SUBMISSION_TYPES.IDEA) {
        await addDoc(collection(db, submissionsTable), {
          images,
          type,
          text,
          creator: user.uid,
          questId: quest.id,
          createdAt: new Date(),
          lastUpdated: new Date(),
          rejected,
          title,
        });

        trackEvent("Created Quest Submission", {
          quest_id: quest.id,
          quest_name: quest.title,
          storyworld_id: quest.storyworld,
          storyworld_name: storyworld.title,
          time_spent_seconds: Math.abs(new Date() - startTimestamp) / 1000,
          title: `${storyworld.title}: ${quest.title}`,
          type: "idea",
          hasSearchImage,
          hasUploadImage,
        });
      } else if (type === SUBMISSION_TYPES.TEXT) {
        await addDoc(collection(db, submissionsTable), {
          type,
          text,
          creator: user.uid,
          questId: quest.id,
          createdAt: new Date(),
          lastUpdated: new Date(),
          rejected,
          title,
        });

        trackEvent("Created Quest Submission", {
          quest_id: quest.id,
          quest_name: quest.title,
          storyworld_id: quest.storyworld,
          storyworld_name: storyworld.title,
          time_spent_seconds: Math.abs(new Date() - startTimestamp) / 1000,
          title: `${storyworld.title}: ${quest.title}`,
          type: "writing",
        });
      }
    }
  },
  getOneById: async (id) => {
    const data = await queryOne({ table, id });
    return data;
  },
  getManyByUserId: async (userId, ...args) => {
    const data = await queryMany({
      table,
      conditions: [["creator", "==", userId], ...args],
    });

    return data;
  },
  getProfileSubmisisons: async (userId, ...args) => {
    const data = await queryMany({
      table,
      conditions: [
        ["creator", "==", userId],
        ["type", "==", SUBMISSION_TYPES.IDEA],
        ...args,
      ],
    });

    return data;
  },
  getProfileLiked: async (userId) => {
    const likes = await queryMany({
      table: likesTable,
      conditions: [["creator", "==", userId]],
    });

    const subSetId = new Set();
    likes.forEach((l) => subSetId.add(l.submissionId));

    if (subSetId.size === 0) return [];

    return batchQuery({
      table,
      ids: Array.from(subSetId),
      conditions: ["type", "==", SUBMISSION_TYPES.IDEA],
    });
  },
  getMany: async (...args) => {
    const data = await queryMany({
      table,
      conditions: [...args],
    });

    return data;
  },
  count: async (...args) => {
    const data = await count({
      table,
      conditions: [...args],
    });

    return data;
  },
  getGalleryPage: async (questId, pageNumber, lastVisibleDocument) => {
    const after = pageNumber === 0 ? undefined : lastVisibleDocument;

    const q = after
      ? query(
          collection(db, submissionsTable),
          where("questId", "==", questId),
          where("rejected", "==", false),
          where("imageUrl", "!=", ""),
          orderBy("imageUrl"),
          orderBy("rejected"),
          orderBy("createdAt"),
          startAfter(after)
        )
      : query(
          collection(db, submissionsTable),
          where("questId", "==", questId),
          where("rejected", "==", false),
          where("imageUrl", "!=", ""),
          orderBy("imageUrl"),
          orderBy("rejected"),
          orderBy("createdAt")
        );

    const querySnapshot = await getDocs(q);
    const results = await getResultsFromSnapshot(querySnapshot);

    return { results, documentSnapshots: querySnapshot };
  },
  getGalleryCount: async (questId) => {
    const q = query(
      collection(db, submissionsTable),
      where("questId", "==", questId),
      where("rejected", "==", false),
      where("imageUrl", "!=", "")
    );
    const querySnapshot = await getCountFromServer(q);

    return querySnapshot.data().count;
  },
};

export default submissionModel;
