import { createContext, useRef } from "react";
import useComment from "../helpers/useComment";

export const CommentInputContext = createContext();

function CommentInputProvider({ value, children }) {
  const commentInputRef = useRef();
  const {
    commentState,
    dispatch,
    isSending,
    replyToUsername,
    startCommentSubmit,
    setDeletedComments,
    notificationContextHolder,
  } = useComment(value?.trackingData);

  const isFocused = document.activeElement === commentInputRef.current;

  return (
    <CommentInputContext.Provider
      value={{
        commentInputRef,
        isFocused,
        commentState,
        dispatch,
        isSending,
        replyToUsername,
        startCommentSubmit,
        setDeletedComments,
      }}
    >
      {notificationContextHolder}
      {children}
    </CommentInputContext.Provider>
  );
}

export default CommentInputProvider;
